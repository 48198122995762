import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { NgIf } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';

import { PearlFormFieldComponent } from '../pearl-components';
import { SafeHtmlPipe } from '../../helpers/pipes';

export interface ConfirmDialogModel {
  title: string;
  message: string;
  leadingImageUrl?: string;
  isHtml?: boolean;
  confirmMessage?: string;
  color?: string;
  /** If defined, a text area will be present in the dialog, which content will be bound to "commentText" in the component */
  commentPlaceholder?: string;
}

@Component({
  selector: 'confirm-dialog',
  templateUrl: './confirm-dialog.html',
  styleUrls: ['./confirm-dialog.scss'],
  standalone: true,
  imports: [
    NgIf,
    MatDialogModule,
    MatButtonModule,
    FormsModule,
    MatInputModule,
    PearlFormFieldComponent,
    SafeHtmlPipe,
  ],
})
export class ConfirmDialogComponent {
  public commentText: string;

  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public confirmConfig: ConfirmDialogModel,
  ) {
  }

  onConfirm(): void {
    this.dialogRef.close(true);
  }

  onDismiss(): void {
    this.dialogRef.close(false);
  }
}
