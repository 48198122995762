<h1 *ngIf="confirmConfig.title" mat-dialog-title>{{ confirmConfig.title }}</h1>

<div mat-dialog-content>
  @if (confirmConfig.leadingImageUrl) {
    <img [src]="confirmConfig.leadingImageUrl" />
  }

  @if (confirmConfig.message) {
    @if (confirmConfig.isHtml) {
      <div [innerHTML]="confirmConfig.message | safeHtml" class="message"></div>
    } @else {
      <p class="message">{{ confirmConfig.message }}</p>
    }
  }
  @if (confirmConfig.commentPlaceholder) {
    <pearl-form-field [label]="confirmConfig.commentPlaceholder">
      <textarea matInput [(ngModel)]="commentText" rows="4"></textarea>
    </pearl-form-field>
  }
</div>

<div mat-dialog-actions class="confirm-actions">
  <button id="confirm-dialog_button-cancel" mat-button (click)="onDismiss()">Cancel</button>
  <button
    id="confirm-dialog_button-confirm"
    mat-raised-button
    [color]="confirmConfig.color ?? 'primary'"
    (click)="onConfirm()">
    {{ confirmConfig.confirmMessage ?? "Yes" }}
  </button>
</div>
